import { useMemo, useState } from "react";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_Row,
} from "mantine-react-table";
import { VisibilityState } from "@tanstack/react-table";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useLocation } from "react-router-dom";

// Custom hook for managing column visibility state with localStorage
export const useColumnVisibility = (
  defaultColumnVisibility: VisibilityState,
  localStorageKey: string
) => {
  // Initialize state from localStorage or use default if not present
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    () => {
      const storedVisibility = localStorage.getItem(localStorageKey);
      try {
        return storedVisibility
          ? JSON.parse(storedVisibility)
          : defaultColumnVisibility;
      } catch (error) {
        console.error(
          "Failed to parse column visibility from local storage:",
          error
        );
        return defaultColumnVisibility;
      }
    }
  );

  return { columnVisibility, setColumnVisibility };
};

// CSV configuration for exporting data
const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
  useBom: true,
});

// Function to handle exporting selected rows
export const handleExportRows = <T extends Record<string, any>>(
  rows: MRT_Row<T>[],
  transformRowData: (original: T) => any
) => {
  const rowData = rows.map((row) => transformRowData(row.original));
  const csv = generateCsv(csvConfig)(rowData);
  download(csvConfig)(csv);
};

// Function to handle exporting all data
export const handleExportData = <T extends Record<string, any>>(
  data: { data: T[] } | null,
  transformRowData: (original: T) => any
) => {
  const rowData = (data?.data ?? []).map(transformRowData);
  const csv = generateCsv(csvConfig)(rowData);
  download(csvConfig)(csv);
};

// Function to update query parameters in the URL
export const updateQueryParams = (params: {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
  customFilters?: any;
}) => {
  const queryString = buildQueryParams(params);
  window.history.replaceState({}, "", `${location.pathname}?${queryString}`);
};

// Function to build query parameters string
export const buildQueryParams = ({
  columnFilters,
  sorting,
  pagination,
  customFilters,
}: {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
  customFilters?: any;
}) => {
  const searchParams = new URLSearchParams();
  const filtersWithCustom = [
    ...(columnFilters ?? []),
    ...Object.entries(customFilters ?? {}).map(([key, value]) => ({
      id: key,
      value,
    })),
  ];
  searchParams.set("filters", JSON.stringify(filtersWithCustom));
  searchParams.set("sorting", JSON.stringify(sorting ?? []));
  searchParams.set("pagination", JSON.stringify(pagination ?? {}));
  return searchParams.toString();
};

// Hook to parse query parameters from the URL
function useQueryParams() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

// Helper function to get filter value from query parameters
function getFilterValue(
  queryParams: URLSearchParams,
  key: string,
  defaultValue: any
) {
  const value = queryParams.get(key);
  try {
    return value !== null ? JSON.parse(value) : defaultValue;
  } catch (e) {
    console.error(`Error parsing query param ${key}:`, e);
    return defaultValue;
  }
}

// Custom hook to manage table state with initial values from query parameters
export function useMantineTableState() {
  const queryParams = useQueryParams();

  const initialSorting = useMemo(
    () => getFilterValue(queryParams, "sorting", []),
    [queryParams]
  );

  const initialPagination = useMemo(
    () =>
      getFilterValue(queryParams, "pagination", {
        pageIndex: 0,
        pageSize: 50,
      }),
    [queryParams]
  );

  const [sorting, setSorting] = useState(initialSorting);
  const [pagination, setPagination] = useState(initialPagination);

  return {
    sorting,
    setSorting,
    pagination,
    setPagination,
  };
}
