import { MRT_ColumnDef } from "mantine-react-table";
import { SwingDto } from "../../../contracts/models/SwingDto";
import { getStatusString } from "./swing-list-utils";
import Status from "../../jobs/status.component";
import moment from "moment";
import React from "react";

const columns: MRT_ColumnDef<SwingDto>[] = [
  {
    accessorFn: (swing: SwingDto) => getStatusString(swing.jobs || []),
    Cell: ({ cell }) => <Status status={cell.getValue() as string} />,
    header: "Status*",
    enableColumnFilter: false,
    id: "status",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing?.collectionDate
        ? moment(swing.collectionDate).format("DD/MM/YYYY")
        : "",
    header: "Swing Date",
    enableColumnFilter: false,
    enableSorting: true,
    id: "collectionDate",
  },
  {
    accessorFn: (swing: SwingDto) => swing.jobReference,
    header: "Swing Reference",
    enableColumnFilter: false,
    enableSorting: true,
    id: "jobReference",
  },
  {
    accessorFn: (swing: SwingDto) => swing.customer?.name,
    header: "Customer",
    enableColumnFilter: false,
    enableSorting: true,
    id: "customer",
  },
  {
    accessorFn: (swing: SwingDto) => swing.poAmount,
    header: "PO Amount",
    enableColumnFilter: false,
    enableSorting: true,
    id: "poAmount",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.poDate ? moment(swing.poDate).format("DD/MM/YYYY") : "",
    header: "PO Date",
    enableColumnFilter: false,
    enableSorting: true,
    id: "poDate",
  },
  {
    accessorFn: (swing: SwingDto) => swing.invoiceNumber,
    header: "Invoice Number",
    enableColumnFilter: false,
    enableSorting: true,
    id: "invoiceNumber",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.jobEndDate ? moment(swing.jobEndDate).format("DD/MM/YYYY") : "",
    header: "Job End Date",
    enableColumnFilter: false,
    enableSorting: true,
    id: "jobEndDate",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.jobs?.length ? swing.jobs.length : 0,
    header: "Job Count",
    enableColumnFilter: false,
    enableSorting: true,
    id: "jobCount",
  },
  {
    accessorFn: (swing: SwingDto) => swing.jobType,
    header: "Job Type",
    enableColumnFilter: false,
    enableSorting: true,
    id: "jobType",
  },
  {
    accessorFn: (swing: SwingDto) => swing.paymentStatus,
    header: "Payment Status",
    enableColumnFilter: false,
    enableSorting: true,
    id: "paymentStatus",
  },
  {
    accessorFn: (swing: SwingDto) => swing.swingDiscriminator,
    header: "Swing Discriminator",
    enableColumnFilter: false,
    enableSorting: true,
    id: "swingDiscriminator",
  },
  {
    accessorFn: (swing: SwingDto) => moment(swing.meetTime).format("HH:mm"),
    header: "Meet Time",
    enableColumnFilter: false,
    enableSorting: true,
    id: "meetTime",
  },
  {
    accessorFn: (swing: SwingDto) => swing.shiftLength,
    header: "Shift Length",
    enableColumnFilter: false,
    enableSorting: true,
    id: "shiftLength",
  },
  {
    accessorFn: (swing: SwingDto) => swing.meetLocationId,
    header: "Meet Location ID",
    enableColumnFilter: false,
    enableSorting: true,
    id: "meetLocationId",
  },
  {
    accessorFn: (swing: SwingDto) => swing.meetLocation?.line1,
    header: "Meet Location",
    enableColumnFilter: false,
    enableSorting: true,
    id: "meetLocation",
  },
  {
    accessorFn: (swing: SwingDto) => swing.customerId,
    header: "Customer ID",
    enableColumnFilter: false,
    enableSorting: true,
    id: "customerId",
  },
  {
    accessorFn: (swing: SwingDto) => swing.overnightMove,
    header: "Overnight Move",
    enableColumnFilter: false,
    enableSorting: true,
    id: "overnightMove",
  },
  {
    accessorFn: (swing: SwingDto) => swing.plannedOutOfHours,
    header: "Planned Out of Hours",
    enableColumnFilter: false,
    enableSorting: true,
    id: "plannedOutOfHours",
  },
  {
    accessorFn: (swing: SwingDto) => swing.groundTransportRequirement,
    header: "Ground Transport Requirement",
    enableColumnFilter: false,
    enableSorting: true,
    id: "groundTransportRequirement",
  },
  {
    accessorFn: (swing: SwingDto) => swing.readableFromLocation,
    header: "Readable From Location",
    enableColumnFilter: false,
    enableSorting: true,
    id: "readableFromLocation",
  },
  {
    accessorFn: (swing: SwingDto) => swing.readableToLocation,
    header: "Readable To Location",
    enableColumnFilter: false,
    enableSorting: true,
    id: "readableToLocation",
  },
  {
    accessorFn: (swing: SwingDto) => swing.parentSwingId,
    header: "Parent Swing ID",
    enableColumnFilter: false,
    enableSorting: true,
    id: "parentSwingId",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.createdAt ? moment(swing.createdAt).format("DD/MM/YYYY") : "",
    header: "Created At",
    enableColumnFilter: false,
    enableSorting: true,
    id: "createdAt",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.updatedAt ? moment(swing.updatedAt).format("DD/MM/YYYY") : "",
    header: "Updated At",
    enableColumnFilter: false,
    enableSorting: true,
    id: "updatedAt",
  },
  {
    accessorFn: (swing: SwingDto) => swing.poNumber,
    header: "PO Number",
    enableColumnFilter: false,
    enableSorting: true,
    id: "poNumber",
  },
  {
    accessorFn: (swing: SwingDto) => swing.invoiceAmount,
    header: "Invoice Amount",
    enableColumnFilter: false,
    enableSorting: true,
    id: "invoiceAmount",
  },
  {
    accessorFn: (swing: SwingDto) =>
      swing.invoiceDate ? moment(swing.invoiceDate).format("DD/MM/YYYY") : "",
    header: "Invoice Date",
    enableColumnFilter: false,
    enableSorting: true,
    id: "invoiceDate",
  },
  {
    accessorFn: (swing: SwingDto) => swing.discountType,
    header: "Discount Type",
    enableColumnFilter: false,
    enableSorting: true,
    id: "discountType",
  },
  {
    accessorFn: (swing: SwingDto) => swing.discountAmount,
    header: "Discount Amount",
    enableColumnFilter: false,
    enableSorting: true,
    id: "discountAmount",
  },
];

export const defaultColumnVisibility = {
  status: true,
  collectionDate: true,
  jobReference: true,
  customer: true,
  poAmount: false,
  poDate: false,
  invoiceNumber: false,
  jobEndDate: false,
  jobCount: false,
  jobType: false,
  paymentStatus: false,
  swingDiscriminator: false,
  meetTime: false,
  shiftLength: false,
  meetLocationId: false,
  meetLocation: false,
  customerId: false,
  overnightMove: false,
  plannedOutOfHours: false,
  groundTransportRequirement: false,
  readableFromLocation: false,
  readableToLocation: false,
  parentSwingId: false,
  customerJobRequestAllocation: false,
  createdAt: false,
  updatedAt: false,
  poNumber: false,
  invoiceAmount: false,
  invoiceDate: false,
  discountType: false,
  discountAmount: false,
};

export { columns };
