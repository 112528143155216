import { JobDto } from "../../../contracts/models/JobDto";

export const hasJobWithStatus = (jobs: JobDto[], status: any) => {
  if (!jobs) return false;
  return jobs.filter((j: any) => j.status === status).length > 0;
};

export const getStatusString = (jobs: any): string => {
  const statuses = [
    "CANCELLED",
    "FAILED",
    "PENDING",
    "IN PROGRESS",
    "AVAILABLE",
    "ALLOCATED",
    "ACCEPTED",
    "COMPLETED",
    "CLOSED",
  ];
  for (const status of statuses) {
    if (hasJobWithStatus(jobs, status)) {
      return status;
    }
  }
  return "";
};