import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
const AddJobSchema = Yup.object().shape({
  jobDescription: Yup.string()
    .required("Description is required")
    .min(2, "Description is too short")
    .max(300, "Description is too long"),
  jobLength: Yup.number()
    .required("Length is required")
    .min(1, "Length is too short")
    .max(300, "Length is too long"),
  subcontractorName: Yup.string()
    .min(2, "Name is too short")
    .max(300, "Name is too long"),
  backendJobNotes: Yup.string()
    .min(2, "Notes is too short")
    .max(300, "Notes is too long"),
});

export default function EditJob({ job, onSubmit }: any) {
  useEffect(() => {}, []);

  const initialValues = {
    jobDescription: job.jobDescription,
    jobLength: job.jobLength,
    subcontractorName: job.subcontractorName,
    backendJobNotes: job.backendJobNotes,
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <Formik
          initialValues={initialValues}
          validationSchema={AddJobSchema}
          onSubmit={(values, { resetForm }) => {
            // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ jobDescrip... Remove this comment to see the full error message
            values.id = job.id;
            onSubmit(values);
          }}
        >
          {({ errors, touched, setFieldValue, isValid, dirty }) => (
            <Form>
              <div className="form-group">
                <label htmlFor="name">Edit Job Description</label>
                <div className="input-group mb-3">
                  {errors.jobDescription && touched.jobDescription ? (
                    <div className="alert alert-danger" role="alert">
                      {errors.jobDescription.toString()}
                    </div>
                  ) : null}
                  <Field className="form-control" name="jobDescription" />
                  {touched.jobDescription && dirty && (
                    <div className="input-group-append">
                      <button
                        className="input-group-text"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="name">Edit Job Length</label>
                <div className="input-group mb-3">
                  {errors.jobLength && touched.jobLength ? (
                    <div className="alert alert-danger" role="alert">
                      {errors.jobLength.toString()}
                    </div>
                  ) : null}
                  <Field
                    className="form-control"
                    name="jobLength"
                    type="number"
                  />
                  {touched.jobLength && dirty && (
                    <div className="input-group-append">
                      <button
                        className="input-group-text"
                        id="basic-addon2"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="name">Override Driver or Subcontractor Name</label>
                <div className="input-group mb-3">
                  <Field className="form-control" name="subcontractorName" />
                  {touched.subcontractorName && dirty && (
                  <div className="input-group-append">
                    <button className="input-group-text" type="submit">Save</button>
                  </div>
                )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="name">Internal Job Notes</label>
                <div className="input-group mb-3">
                  <Field className="form-control" as="textarea" name="backendJobNotes" />
                  {touched.backendJobNotes && dirty && (
                    <div className="input-group-append">
                      <button
                        className="input-group-text"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
