import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import JobService from "../../services/job.service";
import LoadingSpinner from "../loading/loading-spinner.component";
import Moment from "react-moment";
import "./Pending.css";
import { useQueryClient } from "@tanstack/react-query";

import { Dropdown } from "react-bootstrap";

const MySwal = withReactContent(Swal);

export default function PendingDrivers({
  job,
  pendingDrivers,
  onUpdate,
  loading,
}: any) {
  const deleteDriver = (driverId: any) => {
    JobService.removePendingDrivers([driverId], job.id).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Drivers successfully removed.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        onUpdate();
      },
      (error) => {
        MySwal.fire({
          title: "Error removing driver",
          text: error.message,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const manualAllocateDriver = (driverId: any) => {
    JobService.manualAllocateDriver(driverId, job.id).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Drivers successfully assigned.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        onUpdate();
      },
      (error) => {
        MySwal.fire({
          title: "Error assigning driver",
          text: error.message,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const allocateDriver = (driverId: any) => {
    JobService.allocateDriver(driverId, job.id).then(
      (response) => {
        MySwal.fire({
          title: "Success",
          text: "Drivers successfully allocated.",
          icon: "success",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
        onUpdate();
      },
      (error) => {
        MySwal.fire({
          title: "Error allocating driver",
          text: error.message,
          icon: "error",
          // @ts-expect-error TS(2769): No overload matches this call.
          button: "Continue",
        });
      }
    );
  };

  const getDriverClasses = (classes: any) => {
    return classes
      .map((c: any) => c.class.name)
      .map((m: any) => <span className="badge bg-primary">{m}</span>);
  };

  let pending = pendingDrivers.map((item: any) => (
    <tr key={item.id}>
      <th scope="row">
        {item.driver?.name}{" "}
        {item.driver?.isTeamLeader && (
          <span className="badge bg-warning">Team Leader</span>
        )}
        {item.driver?.isPayee && (
          <span className="badge bg-success">Full Time</span>
        )}
      </th>
      <td>{getDriverClasses(item.driver?.licenceClasses)}</td>
      <td>{item.driver?.rating}</td>
      <td>{item.totalJobs}</td>
      <td>
        {item.lastDriverJob && (
          <Moment format="DD/MM/YYYY" date={new Date(item.lastDriverJob)} />
        )}
      </td>
      <td>{item.availabilityRequestInLastMonth}</td>
      <td>{item.confirmedAvailabilityInLastMonth}</td>
      <td>{(item.monthlyAvailabilityRatio * 100).toFixed(2)}%</td>
      <td>{(item.availableAssignedRatioLastMonth * 100).toFixed(2)}%</td>
      {item.driverAvailable ? (
        <td className="center">
          <button
            className="btn btn-success"
            onClick={() => allocateDriver(item.driver.id)}
          >
            Allocate Job
          </button>
        </td>
      ) : (
        <td className="center">
          <button
            className="btn btn-warning"
            onClick={() => manualAllocateDriver(item.driver.id)}
          >
            Override Assign
          </button>
          {item.driverAvailableAt == null ? (
            <span className="orange">Awaiting Response</span>
          ) : (
            <span className="red">Not Available</span>
          )}
        </td>
      )}
      <td className="center">
        <button
          className="btn btn-danger"
          onClick={() => deleteDriver(item.driver.id)}
        >
          Remove
        </button>
      </td>
    </tr>
  ));

  return (
    <>
      {loading && <LoadingSpinner></LoadingSpinner>}
      {!loading && (
        <div className="row">
          <div className="col">
            <p className="available-title">Available Drivers</p>
            <p>
              The following drivers have had their availability for this job
              requested. If available, you can allocate the job to them.
            </p>

            {pendingDrivers && pendingDrivers.length > 0 && (
              <div className="job-list">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Licence Classes</th>
                        <th scope="col">Rating</th>
                        <th scope="col">Total Jobs</th>
                        <th scope="col">Last Job Date</th>
                        <th scope="col">Requests</th>
                        <th scope="col">Availability Confirmations</th>
                        <th scope="col">Availability Ratio*</th>
                        <th scope="col">Assigned Availability Ratio*</th>
                        <th>
                          <td className="center">
                            <Dropdown>
                              <Dropdown.Toggle>Sort by</Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    // sortBy("AVAILABILITY")
                                    console.log("")
                                  }
                                >
                                  Availability Assigned
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{pending}</tbody>
                  </table>
                  <p className="small right">*Ratio based on last 60d</p>
                  <p className="small right">Drivers with jobs shown first</p>
                </div>
              </div>
            )}

            {(!pendingDrivers || pendingDrivers.length === 0) && (
              <p>No available drivers, add some above!</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
